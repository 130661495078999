<template>
  <div class="tab-pane fade" id="nav-range" role="tabpanel" aria-labelledby="nav-range-tab">
    <div class="compositionarea_topber d-flex" :class="{'align-items-center justify-content-between': device==='desktop', 'flex-column': device==='mobile'}">
      <div class="compositionarea_topber_left">
        <div class="compositiondropdown_box d-flex align-items-center justify-content-between">
          <h4>{{$t("sabre.search-panel.nights")}}</h4>
          <spin-plus-minus-button v-model="nights"/>
        </div>
      </div>
      <div class="compositionarea_topber_right d-flex align-items-center">

        <date-info-tooltip/>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="weekend" value="" :checked="weekendTargeted" @change="weekendChanged">
          <label class="form-check-label" for="weekend">{{$t("sabre.search-panel.weekend")}}</label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="weekend1" value="option5" :checked="midweekTargeted" @change="midweekChanged">
          <label class="form-check-label" for="weekend1">{{$t("sabre.search-panel.midweek")}}</label>
        </div>
      </div>
    </div>

    <date-range-control v-model="selectedDateRange" v-if="device==='desktop'"/>

    <div class="compositionarea_footer d-flex align-items-center justify-content-between" v-if="device==='desktop'">
      <div class="btn-group-area d-flex justify-content-start">
        <button class="holiday_btn">{{$t("sabre.search-panel.holiday")}}</button>
        <button class="specailday_btn">{{$t("sabre.search-panel.special-day")}}</button>
        <button class="specailday_btn1">{{$t("sabre.search-panel.special-day")}}</button>
      </div>
      <div class="btn-group-area d-flex justify-content-end" v-if="device==='desktop'">
        <button class="cleanup_btn" @click="clearUp">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="chooseDate">{{$t("sabre.buttons.save")}}</button>
      </div>
    </div>

    <date-range-control v-model="selectedDateRange" v-if="device==='mobile'"/>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'sabre-date-range',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  components: {
    DateRangeControl: () => import('./dateRangeControl'),
    DateInfoTooltip: () => import('./dateInfoTooltip'),
    // CustomDatepicker: () => import('./appDaterangePicker'),
    SpinPlusMinusButton: () => import('@/sabre/common/atom/spinPlusMinusButton'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      storedDateRange: 'GET_SABRE_SPECIFIC_DATE_RANGE',
      storedNights: 'GET_SABRE_NIGHTS_FOR_SEARCH',
      weekendTargeted: 'GET_SABRE_WEEKEND_TARGETED_FOR_SEARCH',
      midweekTargeted: 'GET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH',
    }),
  },
  watch: {
    selectedDateRange() {
      this.$store.commit('SET_SABRE_SPECIFIC_DATE_RANGE', this.selectedDateRange);
    },
    nights() {
      this.$store.commit('SET_SABRE_NIGHTS_FOR_SEARCH', this.nights);
    },
    storedNights() {
      this.nights = +this.storedNights;
    },
  },
  data() {
    return {
      nights: 0,
      weekend: false,
      midweek: false,
      selectedDateRange: [],
    };
  },
  mounted() {
    this.selectedDateRange = this.storedDateRange;
    this.nights = this.storedNights;
  },
  methods: {
    chooseDate() {
      this.$emit('close');
    },
    clearUp() {
      const dateControl = this.$children.find((child) => child.$options._componentTag === 'date-range-control');
      dateControl.clear();
      this.selectedDateRange = [];
    },
    weekendChanged() {
      this.$store.commit('SET_SABRE_WEEKEND_TARGETED_FOR_SEARCH', !this.weekendTargeted);
    },
    midweekChanged() {
      this.$store.commit('SET_SABRE_MIDWEEK_TARGETED_FOR_SEARCH', !this.midweekTargeted);
    },
  },
};

</script>
